import PropTypes from "prop-types"
import React from "react"

const TopBar = () => (
    <div
        className="navbar top-bar bg-white border-bottom border-color-black-transparent navbar-boxed d-none d-md-inline-block">
        <div className="container-fluid nav-header-container">


        </div>
    </div>

)

TopBar.propTypes = {
  siteTitle: PropTypes.string
}

TopBar.defaultProps = {
  siteTitle: ``
}

export default TopBar
