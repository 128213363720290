import PropTypes from "prop-types"
import React from "react"
import TopBar from "./top-bar"
import Menu from "../menu"

const Header = ({ location }) => (
    <header className="header-with-topbar index sticky header-appear">
        <TopBar/>
        <nav
            className="navbar navbar-expand-lg navbar-dark bg-transparent border-bottom border-color-white-transparent header-light fixed-top navbar-boxed header-reverse-scroll">
            <div className="container-fluid nav-header-container">
                <Menu location={location}/>
            </div>
        </nav>
    </header>

)

Header.propTypes = {
    siteTitle: PropTypes.string
}

Header.defaultProps = {
    siteTitle: ``
}

export default Header
